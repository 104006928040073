


import * as React  from 'react';
import { createContext, useEffect, useState } from "react";
import {Md5} from 'ts-md5'
import { Card } from "react-bootstrap";
import Container from 'react-bootstrap/Container';

import Carousel from 'react-bootstrap/Carousel';

import Stack from 'react-bootstrap/Stack';
import Image from 'react-bootstrap/Image';

import Email from '@mui/icons-material/Email';

function CarouselImage(props: any) {
}


function CarouselImageCluster(props: any) {

  const imgcount: number = Object.keys(props.images).length
  const width: string = imgcount == 1 ? "100%" : "25%"
  const shared_style: any = {
    display: "block",
    float: "left",
    height: "30vh",
    width: width,
  };

  let background_styles: any = []

  props.images.map((img: any, index: number) => {

    const xtranslate: string = imgcount == 1 ? "center" :
                               imgcount == 4 ?
                                    index == 0 ? "+50%" :
                                    index == 1 ? "+50%" :
                                    index == 2 ? "-50%" :
                                    index == 3 ? "+50%" :
                                  "0" :
                                "0"

    const ytranslate: string = imgcount == 1 ? "center" :
                               imgcount == 4 ?
                                    index == 0 ? "+50%" :
                                    index == 1 ? "-50%" :
                                    index == 2 ? "+50%" :
                                    index == 3 ? "-50%" :
                                  "0" :
                                "0"

    background_styles.push({
      ...{...shared_style,
      background: `url('${img}') center no-repeat`,
      zIndex: index,
      },
    })

  })

  return(
    <>
      {
        background_styles.map((style: any, index: number) => {
          return (
            <span style={style} key={"bgimg" + props.name + index} ></span>
          )
        })
      }
    </>
  )
}

type CarouselItemContextType = {
  name: string,
  image: string,
  email?: string,
  description1: string,
  description2: string,
  alt: string,
};


export function CarouselComponent(data: any){

  const fade: string = Object.keys(data.data).length == 1 ? "" : "fade-out"

  return (
    <Carousel interval={7000}>
      {Object.values(data.data).map((item: any) => (
        <Carousel.Item key={item.name}>

          <CarouselImageCluster images={item.images} fade={fade} />

          <Carousel.Caption >

            <h3>{item.name}</h3>

            { item.email && <a href="mailto:{item.email}">
                  <Email className="m-2" />
                  {item.email}
            </a> }

            <p>{item.description1}</p>

            { item.description2 && <p>
              {item.description2}
            </p> }

            { item.previousemployment && <h6>Previous employment</h6> }

            { item.previousemployment &&

              Object.entries(item.previousemployment).map(([key, items]) => (
                <>
                <p key={item.name}> {item.previousemployment.name} </p>
                <p key={item.name + "d1"}> {item.previousemployment.description1} </p>
                <p key={item.name + "d2"}> {item.previousemployment.description1} </p>
                </>
              ))
            }

          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
  )
}

